var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: "https://www.uniprot.org/uniprotkb/" + _vm.accession,
        target: "_blank",
        rel: "noopener",
      },
    },
    [_vm._v(_vm._s(_vm.accession))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
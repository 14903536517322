var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { padding: "2em", width: "250px" } },
    [
      _c("h3", [_vm._v("Filter by")]),
      _vm._v(" "),
      _c("v-text-field", {
        staticStyle: { width: "80px", display: "inline-block" },
        attrs: {
          "model-value": _vm.range[0],
          value: _vm.range[0],
          label: "min",
          "single-line": "",
          type: "number",
          variant: "outlined",
          density: "compact",
        },
        on: {
          change: function ($event) {
            return _vm.$set(_vm.range, 0, $event)
          },
        },
      }),
      _vm._v("\n    ~\n    "),
      _c("v-text-field", {
        staticStyle: { width: "80px", display: "inline-block" },
        attrs: {
          "model-value": _vm.range[1],
          value: _vm.range[1],
          label: "min",
          "single-line": "",
          type: "number",
          variant: "outlined",
          density: "compact",
        },
        on: {
          change: function ($event) {
            return _vm.$set(_vm.range, 1, $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._b(
      {
        staticClass: "btn btn--raised btn--file",
        staticStyle: { position: "relative" },
        attrs: { type: "button", block: _vm.$vuetify.breakpoint.xsOnly },
        on: {
          drop: function ($event) {
            return _vm.fileDrop($event)
          },
        },
      },
      "v-btn",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "btn__content", attrs: { "aria-hidden": "" } }, [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { "aria-label": _vm.label, type: "file", accept: _vm.accept },
        on: { change: _vm.upload },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
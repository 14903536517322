var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      class: ["ml-0", "pl-3"],
      attrs: { app: "", height: "48px", fixed: "", "clipped-left": "" },
    },
    [
      _c(
        "v-app-bar-title",
        [
          _c(
            "router-link",
            {
              staticStyle: { color: "inherit", "text-decoration": "none" },
              attrs: { to: "/" },
            },
            [_vm._v("BFVD")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("img", {
        staticStyle: {
          "margin-left": "8px",
          display: "inline-block",
          width: "48px",
          height: "48px",
          "vertical-align": "middle",
        },
        attrs: {
          src: require("./assets/marv-foldseek-small.png"),
          "aria-hidden": "true",
        },
      }),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        { staticClass: "hidden-sm-and-down" },
        _vm._l(_vm.links, function (link) {
          return _c(
            "v-btn",
            {
              key: link.title,
              attrs: {
                text: "",
                href: link.href,
                rel: "external noopener",
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(link.title))]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          staticClass: "hidden-md-and-up",
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      { staticClass: "hidden-md-and-up", attrs: { icon: "" } },
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.$MDI.DotsVertical))])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            [
              _vm._l(_vm.links, function (link) {
                return _c(
                  "v-list-item",
                  {
                    key: link.title,
                    attrs: {
                      rel: "external noopener",
                      target: "_blank",
                      href: link.href,
                    },
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [_c("v-list-item-title", [_vm._v(_vm._s(link.title))])],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: {
                    rel: "external noopener",
                    target: "_blank",
                    href: "https://github.com/steineggerlab/afdb-clusters-analysis",
                  },
                },
                [
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("GitHub")])],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
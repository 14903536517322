var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { "open-delay": "300", top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-autocomplete",
                _vm._g(
                  _vm._b(
                    {
                      staticStyle: { "max-width": "400px", margin: "0 auto" },
                      attrs: {
                        outlined: "",
                        value: _vm.value,
                        label: "GO Term",
                        placeholder: "Start typing to search GO terms",
                        "hide-no-data": "",
                        "no-filter": "",
                        items: _vm.items,
                        loading: _vm.isLoading,
                        "search-input": _vm.search,
                        "return-object": "",
                        "auto-select-first": "",
                        "allow-overflow": false,
                        dark: "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.search = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.search = $event
                        },
                        input: _vm.change,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.text) +
                                    " (" +
                                    _vm._s(item.value) +
                                    ")\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "v-autocomplete",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v("Search for Gene Ontology (GO) terms")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
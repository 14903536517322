var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      disabled: _vm.disabled,
      "allow-overflow": false,
      items: _vm.items,
      loading: _vm.isLoading,
      "search-input": _vm.search,
      value: _vm.value,
      placeholder: "Taxonomic filter",
      "hide-no-data": "",
      "return-object": "",
      "auto-select-first": "",
      clearable: "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event
      },
      "update:search-input": function ($event) {
        _vm.search = $event
      },
      input: _vm.change,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n            " +
                _vm._s(item.text) +
                " (" +
                _vm._s(item.rank) +
                ")\n    "
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.taxonomy
    ? _c(
        "a",
        {
          attrs: {
            href:
              "https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?mode=Info&id=" +
              _vm.taxonomy.id,
            title:
              "Taxonomic ID: " +
              _vm.taxonomy.id +
              " Rank: " +
              _vm.taxonomy.rank,
          },
        },
        [_vm._v(_vm._s(_vm.taxonomy.name))]
      )
    : _c("span", [_vm._v("Unknown")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FileButton",
        _vm._b(
          {
            attrs: {
              label: "Upload PDB File",
              disabled: _vm.inSearch,
              loading: _vm.inSearch,
              "x-large": "",
              color: this.error ? "error" : "primary",
              accept:
                ".pdb,.mmcif,.cif,.mcif,chemical/x-pdb,chemical/x-cif,chemical/x-mmcif",
            },
            on: { upload: _vm.upload },
          },
          "FileButton",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.error
        ? _c("div", [_c("small", [_vm._v("Error: " + _vm._s(_vm.error))])])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
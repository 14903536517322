var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c("svg", { ref: "svg", staticClass: "hide", attrs: { width: "100%" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "panel-root",
        _vm.elevation != null ? "elevation-" + _vm.elevation : null,
      ],
    },
    [
      !!_vm.$slots["header"] || !!_vm.header
        ? _c(
            "v-toolbar",
            { attrs: { text: "", dense: "", dark: "" } },
            [
              _vm.collapsible
                ? _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "margin-top": "0",
                        "margin-left": "-15px",
                      },
                      attrs: {
                        icon: "",
                        plain: "",
                        "aria-expanded": _vm.isCollapsed ? "false" : "true",
                        "aria-controls": _vm.uuid,
                      },
                      on: {
                        click: function ($event) {
                          _vm.isCollapsed = !_vm.isCollapsed
                        },
                      },
                    },
                    [
                      _vm.isCollapsed
                        ? _c("v-icon", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$MDI.PlusBox) +
                                "\n            "
                            ),
                          ])
                        : _c("v-icon", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$MDI.MinusBox) +
                                "\n            "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-h6 align-end" },
                [
                  _vm.$slots["header"]
                    ? _vm._t("header")
                    : [_vm._v(_vm._s(_vm.header))],
                ],
                2
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t("toolbar-extra"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCollapsed
        ? _c(
            "v-card",
            {
              class: [
                "panel",
                { "d-flex": _vm.flex },
                { "force-fill-height": _vm.fillHeight },
              ],
              attrs: { rounded: "0", id: _vm.uuid },
            },
            [
              _vm.$slots["desc"]
                ? _c(
                    "v-card-text",
                    { staticClass: "subheading justify" },
                    [_vm._t("desc")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots["content"]
                ? _c(
                    "v-card-text",
                    {
                      class: [
                        "panel-content",
                        "justify",
                        { "d-flex": _vm.flex },
                      ],
                    },
                    [_vm._t("content")],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-chip", { attrs: { color: _vm.flagColor, outlined: "" } }, [
    _vm._v("\n    " + _vm._s(_vm.flagText) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Panel",
    { staticStyle: { "margin-top": "1em" }, attrs: { collapsible: "" } },
    [
      _c("template", { slot: "header" }, [
        _vm._v("\n        UniRef members\n    "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "toolbar-extra" },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { plain: "" } }, on),
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$MDI.Export)),
                          ]),
                          _vm._v(
                            "\n                    Export\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href:
                          _vm.$axios.defaults.baseURL +
                          "/cluster/" +
                          _vm.$route.params.cluster +
                          "/members?format=accessions&" +
                          _vm.requestOptions.params.toString(),
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Accessions")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.params.cluster
        ? _c(
            "template",
            { slot: "content" },
            [
              _c("Sankey", {
                attrs: { cluster: _vm.cluster, type: "members" },
                on: { select: _vm.sankeySelect },
              }),
              _vm._v(" "),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.members,
                  options: _vm.options,
                  "server-items-length": _vm.totalMembers,
                  loading: _vm.loading,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 50, 100, -1],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.accession",
                      fn: function (prop) {
                        return [
                          _c("ExternalLinks", {
                            attrs: { accession: prop.value },
                          }),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(prop.item.description) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    {
                      key: "header.tax_id",
                      fn: function (ref) {
                        var header = ref.header
                        return [
                          _c("TaxonomyAutocomplete", {
                            attrs: {
                              cluster: _vm.cluster,
                              urlFunction: function (a, b) {
                                return (
                                  "/cluster/" + a + "/members/taxonomy/" + b
                                )
                              },
                              options: _vm.requestOptions,
                              disabled: _vm.taxAutocompleteDisabled,
                            },
                            model: {
                              value: _vm.options.tax_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "tax_id", $$v)
                              },
                              expression: "options.tax_id",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "item.tax_id",
                      fn: function (prop) {
                        return [
                          _c("TaxSpan", { attrs: { taxonomy: prop.value } }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2916031623
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }